import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import NoData from '../../../components/NoData';
import PageHeader from '../../../components/PageHeader';
import Sidebar from '../../../components/Sidebar';
import Transitions from '../../../components/Transition';
import { useQuery } from '../../../hooks/useQuery';
import AddModal from './components/AddModal';
import DeleteModal from './components/DeleteModal';
import DownloadModal from './components/DownloadModal';
import ListHeader from './components/header';
import ImportCsvModal from './components/ImportCsvModal';
import List from './components/list';
import SearchAtPage from './components/searchAtPage';
import useCollaborators from './useCollaborators';

export default function Collaborators() {
  const {
    isLoading,
    fullUsers,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredUsers,
    setUserBeingDeleted,
    setModalDeleteShow,
    statusByCode,
    selectedCodStatus,
    handleTryAgain,
    modalImportCsvShow,
    csvFileName,
    handleFileUpload,
    handleSubmitFile,
    modalDeleteShow,
    userBeingDeleted,
    deleteCollaborator,
    modalCreateShow,
    collaboratorId,
    handleChangeStatusFilter,
    addCollaborator,
    handleImportShow,
    handleCreateModalShow,
    handleImportModalClose,
    handleCreateModalClose,
    setCsvAction,
    csvAction,
    pagesQuantity,
    downloadUsers,
    downloadModalShow,
    setDownloadModalShow,
    setSelectedFilterDownload,
    selectedFilterDownload,
    currentPage,
    handlePageChange,
    handleCollaboratorIdChange,
    handleCompanyFormChange,
    handleCompanyChange,
    associatedCompanies,
    selectedCompany,
    selectedCompanyForm,
    downloadUsageRelatory,
    lastUpdate,
  } = useCollaborators();

  const filteredListLength = filteredUsers?.length;
  const hasUsers = fullUsers?.length !== 0 && !!fullUsers;
  const searchNotFound = !filteredUsers && hasUsers;
  const isListEmpty = !doesListApiHasError && (!isLoading && fullUsers.length === 0);

  const { companyName } = useParams();
  const query = useQuery();
  const isReadOnly = query.has('readOnly');
  const viewByAssociatedCompanies = query.has('viewByAssociatedCompanies');

  return (
    <>
      <Sidebar active="Companies" />
      <Transitions>
        <Loader isLoading={isLoading} />

        <PageHeader
          title={`Beneficiários empresa ${companyName}`}
          link='/companies'
          lastUpdate={lastUpdate}
        />

        {hasUsers && (
          <SearchAtPage
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
            selectedFilter={selectedCodStatus}
            onChangeSelectedStatus={handleChangeStatusFilter}
            isReadOnly={isReadOnly}
            handleCompanyChange={handleCompanyChange}
            selectedCompany={selectedCompany}
            associatedCompanies={associatedCompanies}
            viewByAssociatedCompanies={viewByAssociatedCompanies}
          />
        )}

        <ListHeader
          doesListExists={hasUsers}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="beneficiário"
          pluralLabel="beneficiários"
          filePathDownload="/files/Modelo importar usuários Kiddlepass.csv"
          showDownloadModal={() => setDownloadModalShow(true)}
          downloadUsageRelatory={downloadUsageRelatory}
          isManualCreateAtModal
          handleImportShow={handleImportShow}
          handleCreateModalShow={handleCreateModalShow}
          onPageChange={handlePageChange}
          pagesQuantity={pagesQuantity}
          currentPage={currentPage}
          isReadOnly={isReadOnly}
        />

        {hasUsers && (
          <List
            filteredList={filteredUsers}
            setUserBeingDeleted={setUserBeingDeleted}
            setModalDeleteShow={setModalDeleteShow}
            isReadOnly={isReadOnly}
            viewByAssociatedCompanies={viewByAssociatedCompanies}
          />
        )}

        {searchNotFound && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Nenhum resultado foi encontrado
                {' '}
                {searchTerm && 'para'}
                {' '}
                <strong>{searchTerm || ''}</strong>
                {' '}
                com status
                {' '}
                <strong>{statusByCode[selectedCodStatus]}</strong>
                {' '}
                na página atual
              </>
            )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter os colaboradores.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
            )}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhum colaborador cadastrado, clique no botão
                {' '}
                <strong>Novo colaborador</strong>
                {' '}
                ou
                {' '}
                <strong>Importar CSV</strong>
                {' '}
                acima para cadastrar seu(s) primeiro(s)!
              </>
            )}
          />
        )}

        <ImportCsvModal
          modalImportCsvShow={modalImportCsvShow}
          setCsvAction={setCsvAction}
          csvAction={csvAction}
          csvFileName={csvFileName}
          handleFileUpload={handleFileUpload}
          handleSubmitFile={handleSubmitFile}
          handleImportModalClose={handleImportModalClose}
        />

        <DownloadModal
          downloadModalShow={downloadModalShow}
          setSelectedFilterDownload={setSelectedFilterDownload}
          selectedFilterDownload={selectedFilterDownload}
          setDownloadModalShow={setDownloadModalShow}
          downloadUsers={downloadUsers}
        />

        <DeleteModal
          modalDeleteShow={modalDeleteShow}
          userBeingDeleted={userBeingDeleted}
          setModalDeleteShow={setModalDeleteShow}
          deleteCollaborator={deleteCollaborator}
        />

        <AddModal
          modalCreateShow={modalCreateShow}
          collaboratorId={collaboratorId}
          handleCollaboratorIdChange={handleCollaboratorIdChange}
          handleCreateModalClose={handleCreateModalClose}
          addCollaborator={addCollaborator}
          handleCompanyFormChange={handleCompanyFormChange}
          associatedCompanies={associatedCompanies}
          selectedCompanyForm={selectedCompanyForm}
          viewByAssociatedCompanies={viewByAssociatedCompanies}
        />

      </Transitions>
    </>
  );
}
